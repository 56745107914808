import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import {
  ActivatedRoute,
  Data,
  NavigationEnd,
  Params,
  Router,
  UrlSegment,
} from "@angular/router";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { RelatedLinkModel } from "src/app/models/related-link.model";
import { BreadCrumbService } from "../../services/bread-crumb.service";
import { DataService } from "../../services/data.service";
import { BaseComponent } from "../base/base.component";

@Component({
  selector: "app-base-page",
  templateUrl: "./base-page.component.html",
  styles: [],
})
export class BasePageComponent extends BaseComponent implements OnInit {
  protected nodeType: string = "basisSide";
  protected nodeName: string;
  protected twoColumns: boolean = false;
  protected showProcessingTime: boolean = false;

  protected extraParagraphs: any[];
  protected explainingFiles: any[];

  @Input() templateRef: TemplateRef<any>;
  @Input() templateRefTop: TemplateRef<any>;
  @Input() imageTemplateRef: TemplateRef<any>;
  @Input() templateRefExtraText: TemplateRef<any>;

  constructor(
    public meta: Meta,
    public title: Title,
    protected router: Router,
    protected dataService: DataService,
    protected breadcrumbService: BreadCrumbService,
    private route: ActivatedRoute
  ) {
    super(meta, title, router, dataService, breadcrumbService);
  }

  ngOnInit(): void {
    this.dataService.data.subscribe((data: any) => {
      if (data != null) {
        if (data !== undefined) {
          this.extraParagraphs = data.filter(
            (x) => x.contentType === "tekstdata"
          );
          this.explainingFiles = data.filter(
            (x) => x.contentType === "forklarendeFil"
          );
          if (this.openFirstParagraph && this.extraParagraphs.length > 0) {
            this.extraParagraphs[0].showParagraph = true;
          }
        }
      }
    });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(() => {
        let path = this.router.url.replace(/%20/g, "-");
        console.log("path", path);

        let nodeName = path;
        if (this.nodeName !== nodeName) {
          this.nodeName = nodeName;
        }
      });

    this.route.data
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((d: Data) => {
        let nodeName = d["nodeName"];
        if (this.nodeName === undefined && nodeName !== undefined) {
          this.nodeName = nodeName;
        }
        let nodeType = d["nodeType"];
        if (nodeType !== undefined) {
          this.nodeType = nodeType;
        }

        let twoColumns = d["twoColumns"] as boolean;
        if (twoColumns !== undefined) {
          this.twoColumns = twoColumns;
        }

        let showProcessingTime = d["showProcessingTime"] as boolean;
        if (showProcessingTime !== undefined) {
          this.showProcessingTime = showProcessingTime;
        }

        if (this.nodeName !== undefined) {
          this.dataService.loadNode(this.nodeType, this.nodeName);
        }
      });
  }

  toggle(paragraph: any, value: boolean): void {
    paragraph.showParagraph = value;
  }
}
