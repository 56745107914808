import { DomSanitizer, Meta, SafeHtml, Title } from "@angular/platform-browser";
import { Component } from "@angular/core";
import { BaseComponent } from "src/app/shared/components/base/base.component";
import { DataService } from "src/app/shared/services/data.service";
import { takeUntil } from "rxjs/operators";
import { Router } from "@angular/router";
import { BreadCrumbService } from "src/app/shared/services/bread-crumb.service";

@Component({
  selector: "app-faq",
  templateUrl: "./faq.component.html",
  providers: [DataService],
})
export class FaqComponent extends BaseComponent {
  html: SafeHtml;

  faqData: any = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      // { "@type": "Question", "name": "Har eg ei klagesak", "acceptedAnswer": { "@type": "Answer", "text": "Diskrimineringsnemnda avgjer klager på diskriminering og trakassering, medan det er Likestillings- og diskrimineringsombodet som gjev råd og rettleiing til personar som er i tvil om dei skal levera ei klage. Dersom du har spørsmål om noko du har opplevd er diskriminerande eller trakasserande, eller du har spørsmål om rettar og plikter som du trur har noko med likestillings- og diskrimineringsregelverket å gjera, tek du kontakt med ombodet. For meir informasjon, sjå www.ldo.no, send e-post til post@ldo.no eller ring 23 15 73 00.  " } },
      // { "@type": "Question", "name": "Kan eg vera anonym når eg klagar ei sak inn for Diskrimineringsnemnda?", "acceptedAnswer": { "@type": "Answer", "text": "Nei, du kan ikkje vera anonym. Men normalt er det berre motparten som får kjennskap til kven klagar er.\n\nBåde klagar og innklaga må derimot fylgja reglane om teieplikt når det gjeldt dei opplysningane dei vert gjort kjent med i samband med saksbehandlinga i nemnda, les meir på sida vår om regelverket. Det inneber mellom anna at dersom innklaga er ei verksemd, vil reglane om teieplikt avgrensa kva personar i verksemda som kan få kunnskap om opplysningane i klagesaka og identiteten til klagar. Reglane gjeldt både for verksemder og for privatpersonar og brot på teieplikta kan vera straffbart.\n\nNår nemnda har behandla klagesaka, får partane avgjerda tilsendt. I tillegg lagar nemnda ein offentleg versjon av avgjerda som vert publisert på heimesidene våre, på www.lovdata.no og sendt til Likestillings- og diskrimineringsombodet. I den offentlege versjonen er alle opplysningar som kan gjera klagar kjent for andre enn partane, fjerna i tråd med reglane i offentlegheitslova og forvaltingslova om teiepliktige opplysningar." } }
    ],
  };
  openById: number;

  constructor(
    public meta: Meta,
    public title: Title,
    protected router: Router,
    protected dataService: DataService,
    protected breadcrumbService: BreadCrumbService,
    private domSanitizer: DomSanitizer
  ) {
    super(meta, title, router, dataService, breadcrumbService);

    this.dataService.data
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((data: any) => {
        if (data !== undefined) {
          this.parseJsonLD(data);
          this.html = this.getSafeHTML(this.faqData);
        }
      });
  }

  ngOnInit() {
    this.dataService.loadFaqData();
    this.dataService.listData.subscribe((data: any[]) => {
      this.faqData = data;
    });
  }

  panelChange($event, id: number) {
    if ($event == true) {
      this.openById = id;
    }
  }

  togglePanel(id: number): void {
    if (this.openById === id) {
      this.openById = undefined;
    } else {
      this.openById = id;
    }
  }

  parseJsonLD(data: any) {
    var items = [];
    var questions = data.Items;
    questions.forEach((element) => {
      var item = {
        "@type": "Question",
        name: element.Name,
        acceptedAnswer: {
          "@type": "Answer",
          text: element.svar,
        },
      };
      items.push(item);
    });

    this.faqData["mainEntity"] = items;
  }

  getSafeHTML(jsonLD: { [key: string]: any }): SafeHtml {
    const json = jsonLD
      ? JSON.stringify(jsonLD, null, 2).replace(/<\/script>/g, "<\\/script>")
      : "";
    // escape / to prevent script tag in JSON
    const html = `<script type="application/ld+json">${json}</script>`;
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }
}
