import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Meta, Title, DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

import * as moment from 'moment';
import { ComplaintsService, ArkivsakFile } from 'src/app/shared/services/complaints.service';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { DataService } from 'src/app/shared/services/data.service';
import { BreadCrumbService } from 'src/app/shared/services/bread-crumb.service';

@Component({
  selector: 'app-complaint-details-html',
  templateUrl: './complaint-details-html.component.html',
  styles: [],
  providers: []
})
export class ComplaintDetailsHtmlComponent extends BaseComponent {

  arkivSakId: string;

  constructor(
    public meta: Meta,
    public title: Title,
    protected router: Router,
    protected dataService: DataService,
    protected breadcrumbService: BreadCrumbService,
    private route: ActivatedRoute,
    private complaintsService: ComplaintsService) {
    super(meta, title, router, dataService, breadcrumbService);

    this.complaintsService.complaintHtml
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        if (data != null) {
          this.data = data;
          this.title.setTitle('Sak #' + data.ArkivSak.arkivsakIdFriendlyName.toString());
        }
      });


    this.route.params
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(params => {

        if (params['id']) {
          this.arkivSakId = params['id'];

          // check state

          const currentNavigation = this.router.getCurrentNavigation();

          if (currentNavigation.extras && currentNavigation.extras.state) {
            const url = currentNavigation.extras.state.url;
            const name = currentNavigation.extras.state.name;
            const file: ArkivsakFile = {
              name: name,
              url: url
            };

            // Load complaint html
            this.complaintsService.loadComplaintHtml(this.arkivSakId, file);

          } else {
            this.router.navigateByUrl('/showcase/' + this.arkivSakId);
          }
        }
      });
  }


}
